<template>
  <div class="dropdown-wrapper" :class="{'is-selected' : isSelected}">
    <img class="btn-icon" :src="iconSource" alt="">
    <dropdown id="price" :styleObject="styleObject" :class-name="'is-big'" ref="priceDropdown">
        <template v-slot:btn>{{ $t('price') }}</template>
        <template v-slot:body>
          <div>
            <p class="dropdown-title">{{ $t('pricePerNight') }}</p>
            <p class="subtitle">{{ $t('taxesAndFees') }}</p>
          </div>
          <div class="slider-container">
            <div class="price-bars">
              <div class="price-bar" v-bind:style="{ height: priceBar[0] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[1] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[2] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[3] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[4] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[5] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[6] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[7] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[8] + 'px' }"></div>
              <div class="price-bar" v-bind:style="{ height: priceBar[9] + 'px' }"></div>
            </div>
            <div class="slider">
              <vue-slider
                ref="priceSlider"
                v-model="value"
                v-bind="options"
                :tooltip-formatter="formatter"
              ></vue-slider>
            </div>
          </div>
          <div class="dropdown-button-holder">
            <button @click="onRestore" class="btn btn-restore">{{ $t('restore') }}</button>
            <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
          </div>
        </template>
    </dropdown>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import Dropdown from '@/components/Dropdown'
import { calculateHotelPriceHelper } from '@/helpers/calculateHotelPrice'
import {mapGetters} from "vuex";

export default {
  components: {
    vueSlider,
    Dropdown
  },
  props: {
    congress: Object,
    resetAllFilters: Boolean,
    deselectAllFilters: Boolean,
  },
  computed: {
    ...mapGetters(['currency_symbol']),
    hotels() {
      return this.$store.getters.hotels
    },
    iconSource() {
      let color = this.isActive || this.isSelected ? "white" : "black";
      let currency = this.currency_symbol === "$" ? "usd" : "eur"
      return require(`../../../assets/filters-icons/ic_price_${color}_${currency}.svg`)
    },
  },
  data: () => {
    return {
      hoverCount: 0,
      prices: [],
      pricesRange: {
      },
      priceBar: [],
      value: [1, 100],
      isActive: false,
      styleObject: {
        color: '',
        fontSize: ''
      },
      options: {
        width: 'auto',
        height: 4,
        dotSize: 17,
        min: 1,
        max: 0,
        disabled: false,
        show: true,
        useKeyboard: true,
        tooltip: 'always',
        enableCross: false,
        dotStyle: {
          backgroundColor: 'black',
          border: '0.5px solid white',
          color: 'white'
        },
        tooltipStyle: {
          backgroundColor: 'transparent',
          border: 'none',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'light'
        },
        processStyle: {
          backgroundColor: 'black'
        }
      },
      isSelected: false
    }
  },
  mounted () {
    this.$watch(() => {
      return this.$refs.priceDropdown.isHidden
    }, (value) => {
      this.$emit('overlay', { value: !value })
      this.isSelected = !value
    })

    this.$watch(() => {
      return this.isSelected
    }, () => {
      if (!this.isSelected && !this.isActive) {
        this.styleObject = {
          color: 'black',
          backgroundColor: 'lightgray'
        }
      } else {
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      } 
    })

    let prices = this.getPricesSorted()
    this.setMinMax(prices)
    this.prices = prices
    let pricesRange = this.getPricesRange(prices)
    this.setPriceBar(pricesRange)

    if (this.$store.getters.filters.endPrice && this.$store.getters.filters.startPrice) {
      this.isSelected = true
      this.value[0] = this.$store.getters.filters.startPrice
      this.value[1] = this.$store.getters.filters.endPrice
    }
  },

  watch: {
    resetAllFilters: function (newValue) {
      if (newValue === true) {
        this.onRestore()
      }
    },
    deselectAllFilters: function (newValue) {
      if (newValue === true) {
        this.isSelected = false
      }
    },
    hotels: function (newValue, oldValue) {
      if (newValue != oldValue) {
        let prices = this.getPricesSorted()
        this.setMinMax(prices)
        let pricesRange = this.getPricesRange(prices)
        this.setPriceBar(pricesRange)
      }
    },
  },
  methods: {
    formatter (val) {
        return `${val}${this.currency_symbol}`
    },
    onApply () {
      this.$refs.priceDropdown.isHidden = true
      if (this.value[0] === 0) this.value[0]++
      this.$emit('filtered', { name: 'startPrice', value: this.value[0] })
      this.$emit('filtered', { name: 'endPrice', value: this.value[1] })
      this.isActive = true
      this.styleObject = {
        color: 'white',
        backgroundColor: 'black'
      }
    },
    onRestore () {
      this.value = [this.options.min, this.options.max]
      this.$emit('filtered', { name: 'startPrice', value: '' })
      this.$emit('filtered', { name: 'endPrice', value: '' })
      this.$refs.priceDropdown.isHidden = true
      this.isActive = false
      this.styleObject = {
        color: 'black',
        backgroundColor: 'lightgray'
      }
    },
    getPricesSorted() {
      let prices = []
      this.$store.getters.hotels.forEach(hotel => {
        prices.push(calculateHotelPriceHelper(hotel))
      })
      prices = prices.sort((a, b) => {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })
      return prices
    },
    setMinMax(prices) {
      this.options.min = Math.floor(prices[0])
      this.options.max = Math.ceil(prices[prices.length - 1])
      this.value[0] = this.options.min
      this.value[1] = this.options.max
    },
    getPricesRange(prices) {
      const factor = 0.1 * this.options.max
      let countFactor = 1

      let pricesRange = prices.reduce((acum, current) => {
        while (current > factor * countFactor) {
          countFactor++
          acum[factor * countFactor] = 0
        }
        if (current <= factor * countFactor) {
          if (acum[factor * countFactor]) {
            acum[factor * countFactor]++
          } else {
            acum[factor * countFactor] = 1
          }
        }
        return acum
      }, {})
      return pricesRange
    },
    setPriceBar(pricesRange){
      let keys = Object.keys(pricesRange)

      keys.sort((a, b) => a - b)

      const maxValue = pricesRange[[...keys].sort((a, b) => pricesRange[b] - pricesRange[a])[0]]

      for (let i = 0; i < keys.length; i++) {
        let k = keys[i]
        if (pricesRange[k] <= 120) {
          const barHeight = (pricesRange[k] * 120) / maxValue
          this.priceBar.push(barHeight)
        } else {
          this.priceBar.push(120)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-button-holder {
    display: flex;
    justify-content: space-between;
  }

  .slider-container {
    position: relative;
    margin-top: 130px;
    margin-bottom: 70px;
  }
  .price-bars {
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    top: -90px;
    padding: 0 10px;

  }
  .price-bar {
    width: 20%;
    background-color: #a8a8a8;
  }

  .subtitle {
    font-size: 16px;
    font-weight: initial;
    color: #a8a8a8;
    margin-top: 0px;
  }

  .is-selected {
    z-index: 3;
  }

</style>
